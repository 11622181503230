<template>
     <div class="not-found-container">
        <span class="not-found-icon">
            <SearchIcon v-if="windowWidth > 593"/>
            <SearchIconSmall v-else/>
        </span>
        <span class="not-found-text">
            {{ $t('NoResultsFound') }}
        </span>
    </div>
</template>

<script>
import SearchIcon from '@/assets/images/icons/search-no-found-1.svg';
import SearchIconSmall from '@/assets/images/icons/search-no-found-2.svg';

export default {
    components: {
        SearchIcon,
        SearchIconSmall
    },
    data: () => ({ windowWidth: window.innerWidth })
}
</script>

<style scoped lang="scss">
.not-found-container {
    width: 100%;width: 100%;
    height: calc(100vh - 108.5px - 48px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .not-found-text {
        font-weight: 600;
        font-size: 22px;
        line-height: 30px;
        color: #4C4541;
        padding-top: 24px;
    }
}

@media (max-width: 593px) {
    .not-found-container {
        .not-found-text {
            font-size: 16px !important;
            line-height: 26px !important;
            padding-top: 16px;
        }
    }
}
</style>
